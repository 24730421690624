import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { BookingService } from '../services/booking.service';
import { format, addYears, eachWeekOfInterval, isAfter, isBefore, areIntervalsOverlapping } from 'date-fns';
import { BookingView, ParallelBookingItem } from './../services/booking.service';
import { BookingTypeEnum } from '../bookings';
import { convertGapToSlot, convertGapToSlotWeekly, extractGaps, formatToISOTimezone } from '@/utils/helpers/dates.helpers';
import { useEffect } from 'react';
import { StatusResponseEnum } from '../types';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@/stores/userStore';
import { redirectSAML } from '@/api';

export type BookingDates = {
    start: Date
    end: Date
    type: BookingTypeEnum
    seat: number
    user: number
    display: string
}

const extractSlotsFromBooking = (data: BookingView) => {
    if (!data) {
        return ({
            slots: [],
            parallel: []
        })
    }

    const slots = data.items?.reduce<BookingDates[]>((acc, booking) => {
        const type = getBookingType(booking)
        
        if (type === BookingTypeEnum.Regular) {
            return [...acc, {
                type,
                bookingId: booking.id,
                seat: booking.point_id,
                user: booking.user,
                display: booking.user_name,
                start: formatToISOTimezone(booking.start),
                end: formatToISOTimezone(booking.end)
            }]
        }

        if (type === BookingTypeEnum.Recurent) {
            const gapSlots = extractGaps(booking.gap)
            
            return [...acc, ...gapSlots.reduce((acc, gap) => {
                const weeks = eachWeekOfInterval({ start: formatToISOTimezone(booking.start), end: formatToISOTimezone(booking.end) }, { weekStartsOn: 1 })

                const slots = weeks.map((currWeek) => {
                    const slot = convertGapToSlotWeekly(gap, currWeek)

                    return {
                        type,
                        bookingId: booking.id,
                        seat: booking.point_id,
                        user: booking.user,
                        display: booking.user_name,
                        start: formatToISOTimezone(slot.start),
                        end: formatToISOTimezone(slot.end)
                    }
                })

                const filtered = slots
                    .filter(v => !isAfter(v.end, formatToISOTimezone(booking.end)))
                    .filter(slot => areIntervalsOverlapping(
                        { start: slot.start, end: slot.end },
                        { start: formatToISOTimezone(booking.start), end: formatToISOTimezone(booking.end) }
                    ))

                return acc.concat(filtered)
            }, [])]
        }

        if (type === BookingTypeEnum.Constant) {
            return [...acc, {
                type,
                bookingId: booking.id,
                seat: booking.point_id,
                user: booking.user,
                display: booking.user_name,
                start: formatToISOTimezone(booking.start),
                end: addYears(formatToISOTimezone(booking.start), 1000)
            }]
        }

        return acc

    }, [])

    // filtering other bookings for other seats (parallel)
    const filtered = slots.filter(item => item.seat === data.resource)
    // bookings for other seats (parallel)
    const parallel = slots.filter(item => item.seat != data.resource)
    
    console.log(slots, filtered, parallel);
    

    return ({
        slots: filtered,
        parallel
    })
}

const getBookingType = (item: ParallelBookingItem) => {
    return item.rec ? BookingTypeEnum.Recurent : item.end ? BookingTypeEnum.Regular : BookingTypeEnum.Constant
}

export const useParallelBooking = ({ nodeId, userId, weekStart, weekEnd }) => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()
    const navigate = useNavigate()
    const authRef = useUserStore(state => state.authRef)

    const nId = Number(nodeId)
    const uId = Number(userId)

    const { data, isLoading, refetch } = useQuery(
        ['booking_intervals', workspaceId, projectId, nId, uId, weekStart, weekEnd],
        () => BookingService.getParallelBooking({
            workspaceId,
            projectId,
            nodeId: nId,
            userId: uId,
            start: format(new Date(weekStart), "yyyy-MM-dd'T'HH:mm:ss"),
            end: format(new Date(weekEnd), "yyyy-MM-dd'T'HH:mm:ss")
        }),
        {
            enabled: !!workspaceId && !!projectId && !!nodeId,
            select: ({ data }) => {
                const extracted = extractSlotsFromBooking(data.node_booking_view_v2)
                return ({
                    ...data,
                    slots: extracted.slots,
                    parallel: extracted.parallel
                })
            },
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить данные о бронировании' }, { variant: 'error' })
            }
        }
    )

    useEffect(() => {
        if (data && data.status != StatusResponseEnum.Success) {
            if (authRef) {
                redirectSAML(authRef, workspaceId, projectId)
            } else {
                navigate(`/login?workspace_id=${workspaceId}&project_id=${projectId}`)
                enqueueToast({
                    title: 'Не авторизован',
                    message: 'Доступно только авторизованным пользователям'
                }, { variant: 'error' })
            }
        }
    }, [isLoading])


    return { data, isLoading, refetch }
}